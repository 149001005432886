import { useState, useEffect } from 'react';
// import './date-picker.css';
import Select from 'react-select';
import ScrollToSelectedMenuList from '../input-field/input-field-menu-list';

const timeOptions = [...Array(24)].flatMap((_, hour) =>
  [...Array(4)].map((_, quarter) => {
    const minutes = quarter * 15;
    const timeValue = `${hour.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
    return { value: timeValue, label: timeValue };
  })
);

export default function TimePicker({
  label,
  name,
  value,
  required,
  className,
  inline,
  onChange,
  error,
  help,
  ...props
}) {
  const [selectedTime, setSelectedTime] = useState(null); // To store the selected time

  // Handle time selection from dropdown for both datetime and time types
  const handleTimeChange = (selectedOption) => {
    setSelectedTime(selectedOption);

    // If time only, create a Date object with the current date
    const currentTime = new Date();
    const [hours, minutes] = selectedOption.value.split(':');
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0);
    currentTime.setMilliseconds(0);

    // Call onChange with a Date object
    onChange({
      target: {
        name,
        value: currentTime.toISOString(),
        type: 'time',
      },
    });
  };

  // Extract time from value if it's already provided
  useEffect(() => {
    if (value) {
      const dateObj = new Date(value);
      const hours = dateObj.getHours().toString().padStart(2, '0');
      const minutes = dateObj.getMinutes().toString().padStart(2, '0');
      const time = `${hours}:${minutes}`;

      // Find matching time option and set it
      const matchingOption = timeOptions.find(
        (option) => option.value === time
      );
      setSelectedTime(matchingOption);
    }
  }, [value]);

  return (
    <div
      className={`input-field input-field--select2 ${
        error && 'input-field--error'
      } ${className ?? ''}
        ${inline ? 'input-field--inline' : ''}
        `}
    >
      <Select
        placeholder="- Vælg -"
        className="react-select-container"
        classNamePrefix={'react-select'}
        required={required}
        value={selectedTime}
        onChange={handleTimeChange}
        menuShouldScrollIntoView={true}
        options={timeOptions}
        noOptionsMessage={() => 'Ugyldig'}
        menuPortalTarget={document.body}
        isSearchable={false}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        components={{
          MenuList: ScrollToSelectedMenuList,
          DropdownIndicator: () => (
            <svg
              className="react-select__dropdown-indicator"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z"
                fill="rgb(61, 133, 69)"
              />
            </svg>
          ),
        }}
        {...props}
      />
      <label dangerouslySetInnerHTML={{ __html: label }} />
      {help && <span className="help">{help}</span>}
      {error && <span className="error">{error}</span>}
    </div>
  );
}
