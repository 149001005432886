import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import DatePicker from '../date-picker/date-picker';
import TimePicker from '../time-picker/time-picker';
import './input-field.css';

export default function InputField({
  className,
  required = false,
  label,
  inline,
  showLabel,
  value,
  onChange,
  onCreateOption,
  placeholder,
  type = 'text',
  help,
  error,
  disabled,
  forwardRef,
  datePickerPosition,
  datePickerFixed,
  ...props
}) {
  if (type === 'checkbox') {
    return (
      <div
        className={`input-field input-field--checkbox ${
          error && 'input-field--error'
        } ${props.checked && 'input-field--checked'} ${className ?? ''}
        `}
      >
        <label>
          <input
            required={required}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            ref={forwardRef}
            {...props}
          />
          <span dangerouslySetInnerHTML={{ __html: label }} />
        </label>
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    );
  }

  if (type === 'select2') {
    return (
      <div
        className={`input-field input-field--select2 ${
          error && 'input-field--error'
        } ${className ?? ''}
        ${inline ? 'input-field--inline' : ''}
        `}
      >
        <Select
          placeholder="- Vælg -"
          className="react-select-container"
          classNamePrefix={'react-select'}
          required={required}
          value={value}
          onChange={onChange}
          noOptionsMessage={() => 'Ingen resultater'}
          options={
            props.options.map((option) => ({
              value: option.value,
              label: option.label,
            })) || []
          }
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          components={{
            DropdownIndicator: () => (
              <svg
                className="react-select__dropdown-indicator"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z"
                  fill="rgb(61, 133, 69)"
                />
              </svg>
            ),
          }}
          {...props}
        />
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    );
  }

  if (type === 'creatableSelect2') {
    return (
      <div
        className={`input-field input-field--select2 ${
          error && 'input-field--error'
        } ${className ?? ''}
        ${inline ? 'input-field--inline' : ''}
        `}
      >
        <CreatableSelect
          placeholder="- Vælg -"
          className="react-select-container"
          classNamePrefix={'react-select'}
          required={required}
          value={value}
          onChange={onChange}
          onCreateOption={onCreateOption}
          noOptionsMessage={() => 'Tilføj ved at skrive og tryk enter'}
          formatCreateLabel={(inputValue) => `Opret "${inputValue}"`}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          options={
            props.options.map((option) => ({
              value: option.value,
              label: option.label,
            })) || []
          }
          components={{
            DropdownIndicator: () => (
              <svg
                className="react-select__dropdown-indicator"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z"
                  fill="rgb(61, 133, 69)"
                />
              </svg>
            ),
          }}
          {...props}
        />
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    );
  }

  if (type === 'select') {
    return (
      <div
        className={`input-field input-field--select ${
          error && 'input-field--error'
        } ${className ?? ''}
        ${inline ? 'input-field--inline' : ''}
        `}
      >
        <select
          required={required}
          value={value}
          onChange={onChange}
          ref={forwardRef}
          autoComplete="off"
          {...props}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    );
  }

  if (type === 'textarea') {
    return (
      <div
        className={`input-field input-field--textarea ${
          error && 'input-field--error'
        } ${className ?? ''}
        `}
      >
        <textarea
          required={required}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          ref={forwardRef}
          onInput={(e) => {
            e.target.style.height = 'auto';
            e.target.style.height = e.target.scrollHeight + 'px';
          }}
          {...props}
        />
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    );
  }

  if (type === 'switch') {
    return (
      <div className={`input-field input-field--switch ${className ?? ''}`}>
        {showLabel && label && <span>{label}</span>}
        <div>
          <input
            name={label}
            id={label}
            type="checkbox"
            value={value}
            defaultChecked={value}
            onChange={onChange}
            ref={forwardRef}
            disabled={disabled}
            {...props}
          />
          <label htmlFor={label}></label>
        </div>
        {help && <span className="help">{help}</span>}
      </div>
    );
  }

  if (type === 'date' || type === 'datetime') {
    return (
      <DatePicker
        label={label}
        name={props.name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        error={error}
        forwardRef={forwardRef}
        datePickerPosition={datePickerPosition}
        datePickerFixed={datePickerFixed}
        inline={inline}
      />
    );
  }

  if (type === 'time') {
    return (
      <TimePicker
        label={label}
        name={props.name}
        value={value}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        error={error}
        help={help}
      />
    );
  }

  return (
    <div
      className={`input-field ${error && 'input-field--error'} ${
        className ?? ''
      }`}
    >
      <input
        required={required}
        type={type}
        // If disabled, use defaultValue instead of value
        {...(disabled ? { defaultValue: value } : { value })}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        ref={forwardRef}
        {...props}
      />
      <label dangerouslySetInnerHTML={{ __html: label }} />
      {help && <span className="help">{help}</span>}
      {error && <span className="error">{error}</span>}
    </div>
  );
}
