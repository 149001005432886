import { useEffect, useState, useContext, useRef } from 'react';
import { EmployeesContext } from '../../context';
import Button from '../../components/button/button';
import Icon from '../../components/icon/icon';
import './checklist-item.css';
import InputField from '../input-field/input-field';

export default function ChecklistAddItem({
  addTask,
  groupId,
  taskId,
  handleClose,
  className = '',
}) {
  const titleInput = useRef(null);
  const employees = useContext(EmployeesContext);
  const [addItemValues, setAddItemValues] = useState({
    id: crypto.randomUUID(),
    title: '',
    description: '',
    dueDate: null,
    assignedTo: null,
    subTasks: [],
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // If the input is a textarea, set the height of the textarea to fit the content
    if (event.target.tagName === 'TEXTAREA') {
      event.target.style.height = 'auto';
      event.target.style.height = `${event.target.scrollHeight}px`;
    }

    setAddItemValues({ ...addItemValues, [name]: value });
  };

  const handleAddTask = () => {
    if (!addItemValues.title.trim()) return;

    addTask(groupId, taskId, addItemValues);
    setAddItemValues({
      id: crypto.randomUUID(),
      title: '',
      description: '',
      dueDate: null,
      assignedTo: null,
      subTasks: [],
    });
  };

  // Set focus on title input when component mounts
  useEffect(() => {
    titleInput.current.focus();
  }, []);

  return (
    <div className={`checklist-add-item ${className}`}>
      <div className="checklist-item__content-wrapper checklist-item__content-wrapper--edit">
        <textarea
          className={`checklist-item__content__title checklist-item__content__title--edit checklist-item__content__title--edit--active`}
          type="text"
          name="title"
          value={addItemValues.title || ''}
          placeholder="Titel..."
          onChange={handleInputChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleAddTask();
            }
          }}
          ref={titleInput}
        />
        <textarea
          className={`checklist-item__content__description checklist-item__content__description--edit checklist-item__content__description--edit--active`}
          type="text"
          name="description"
          value={addItemValues.description || ''}
          placeholder="Beskrivelse..."
          onChange={handleInputChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleAddTask();
            }
          }}
        />
        <div className="checklist-item__content__meta">
          <div className="checklist-item__content__meta__due-date">
            <span className="checklist-item__content__meta__due-date__label">
              Dato
            </span>{' '}
            <InputField
              type="date"
              inline
              name="dueDate"
              datePickerFixed={true}
              value={addItemValues.dueDate || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="checklist-item__content__meta__assigned-to">
            <span>Tildelt til</span>
            <select
              name="assignedTo"
              defaultValue={''}
              onChange={handleInputChange}
            >
              <option value="none">Vælg medarbejder</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.data.firstName} {employee.data.lastName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          className={`checklist-item__content__edit checklist-item__content__edit--active`}
        >
          <Button
            buttonStyle="text-link"
            className="small"
            onClick={handleClose}
          >
            Luk
          </Button>
          <Button
            disabled={addItemValues.title.trim() === ''}
            className="small"
            onClick={handleAddTask}
          >
            Gem
          </Button>
        </div>
      </div>
    </div>
  );
}
