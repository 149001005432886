import './subscription-plan.css';
import { useState, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  EmployeesContext,
  SubscriptionContext,
  UserInfoContext,
} from '../../context';
import { Panel, Panels } from '../../components/panel/panel';
import Icon from '../../components/icon/icon';
import Button from '../../components/button/button';
import SubscriptionPlansModal from './subscription-plans-modal';
import Dankort from './cards/dankort.svg';
import Visa from './cards/visa.svg';
import Mastercard from './cards/mastercard.svg';
import PLANS from '../../constants/payments/plans';
import ONPAYPARAMSVALUES from '../../constants/payments/onPayParamValues';
import TimeRegistrationAccessModal from '../../components/timeregistration/settings/timeregistration-access-modal';
import InfoBox from '../../components/infobox/infobox';
import AdminAccessModal from '../../components/admin/admin-access-modal/admin-access-modal';

export default function SubscriptionPlan() {
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const [searchParams] = useSearchParams();
  const [isPlansModalOpen, setIsPlansModalOpen] = useState(false);
  const [
    isTimeRegistrationAccessModalOpen,
    setIsTimeRegistrationAccessModalOpen,
  ] = useState(false);
  const [isAdminAccessModalOpen, setIsAdminAccessModalOpen] = useState(false);
  const { subscription, onPaySubscription } = useContext(SubscriptionContext);

  const employeesWithMobileAccess = employees.filter(
    (employee) => employee?.data?.settings?.mobileApp?.access
  );

  const employeesWithAdminOrCompanyOwner = employees
    .filter(
      (employee) =>
        employee?.data?.settings?.role === 'admin' ||
        employee.id === userInfo?.companyData?.ownerUserId
    )
    .filter((employee) => employee.data.employmentStatus !== 'inactive')
    .sort((a, b) => {
      if (a.id === userInfo?.companyData?.ownerUserId) return -1;
      if (b.id === userInfo?.companyData?.ownerUserId) return 1;
      return 0;
    });

  const errorCode =
    ONPAYPARAMSVALUES.errorCode[searchParams.get('onpay_errorcode')];

  async function addCard() {
    try {
      const response = await fetch(`/api/payment/subscriptions/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-uuid': userInfo.id,
        },
        body: JSON.stringify({
          name: subscription?.trial ? subscription.name : 'freemium',
          companyId: userInfo.companyId,
        }),
      });

      // If the response is not ok, throw an error
      if (!response.ok) {
        throw new Error('error', response.data);
      }

      // If the response is ok, redirect to payment
      const data = await response.json();
      window.location = data.paymentLink;
    } catch (error) {
      console.error('Error:', error);
      return;
    }
  }

  async function handleCreditCardChange() {
    if (!subscription?.onPaySubscriptionId || !userInfo?.companyId) return;

    try {
      const response = await fetch(`/api/payment/subscriptions/changecard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-uuid': userInfo.id,
        },
        body: JSON.stringify({
          currentOnPaySubscriptionId: subscription?.onPaySubscriptionId,
          companyId: userInfo.companyId,
        }),
      });

      if (!response.ok) {
        throw new Error('error', response.data);
      }

      const data = await response.json();
      window.location = data.paymentLink;
    } catch (error) {
      console.error('Error:', error);
      return;
    }
  }

  const subscriptionActive =
    subscription &&
    (subscription.status === 'active' || subscription.status === 'cancelled');
  const subscriptionCancelled =
    subscription && subscription.status === 'cancelled';
  const subscriptionTerminated =
    subscription && subscription.status === 'terminated';

  useEffect(() => {
    if (searchParams.get('add_payment')) {
      // Scroll to #add-payment-method
      const element = document.getElementById('add-payment-method');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [searchParams]);

  const expiryMonth = onPaySubscription?.onPayData?.data?.expiry_month;
  const expiryYear = onPaySubscription?.onPayData?.data?.expiry_year;

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const expiryDate = new Date(expiryYear, expiryMonth - 1);
  expiryDate.setHours(0, 0, 0, 0);

  // Check if the credit card is about to expire in the next 30 days
  const thirtyDaysFromNow = new Date();
  thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
  thirtyDaysFromNow.setHours(0, 0, 0, 0);

  return (
    <>
      <Panel
        noBox
        header={
          <>
            <h4>Dit abonnement</h4>
            <p>
              Har du spørgsmål kan du altid komme i kontakt med os via chatten i
              højre hjørne af din skærm.
            </p>
          </>
        }
      >
        <Panels noMargin>
          {subscriptionTerminated && (
            <InfoBox type="danger">
              <p>
                <strong>Dit abonnement er blevet suspenderet.</strong>
                <br />
                Opdater venligst dit betalingskort for at genoptage dit
                abonnement.
                <br />
                Hvis du mener dette er en fejl, kontakt venligst support.
              </p>
            </InfoBox>
          )}
          {errorCode?.error && (
            <InfoBox type="danger">
              <p>
                <strong>Der skete en fejl under betalingen.</strong>
                <br />
                Prøv venligst igen eller kontakt support.
              </p>
            </InfoBox>
          )}
          <Panel
            highlighted={
              subscription && subscription.name !== 'freemium'
                ? 'success'
                : 'default'
            }
          >
            <div
              className={`subscription-plan subscription-plan--${
                subscriptionActive && subscription.name !== 'freemium'
                  ? 'success'
                  : 'default'
              }`}
            >
              <div className="subscription-plan__icon">
                {subscriptionActive && subscription.name !== 'freemium' ? (
                  <Icon name="check" />
                ) : (
                  <Icon name="close" />
                )}
              </div>
              <div className="subscription-plan__inner">
                {!subscriptionCancelled ? (
                  <>
                    <p>
                      <strong>Dit nuværende abonnement:</strong>{' '}
                      {PLANS[subscription?.name?.toUpperCase()]?.label ||
                        'Freemium'}{' '}
                      {subscription?.trial ? '(prøveperiode)' : ''}
                      <br />
                      {subscription?.trial ? (
                        <>
                          <strong>Udløber:</strong>{' '}
                          {new Date(
                            subscription?.nextBillingDate?.toDate()
                          ).toLocaleDateString('da-DK')}
                        </>
                      ) : (
                        <>
                          <strong>Til:</strong>{' '}
                          {(subscription?.price / 100 || 0).toLocaleString(
                            'da-DK'
                          )}{' '}
                          kr. pr. måned.
                        </>
                      )}
                    </p>
                    <p>
                      {subscriptionActive && subscription.name !== 'freemium'
                        ? 'Du har adgang til alle funktioner og kan til enhver tid opgradere eller nedgradere dit abonnement.'
                        : 'Du har adgang til de fleste funktioner. Du kan til enhver tid opgradere dit abonnement ved at trykke på knappen herunder.'}
                    </p>
                  </>
                ) : (
                  subscriptionCancelled && (
                    <>
                      <p>
                        <strong>
                          Du har nedgraderet dit{' '}
                          {PLANS[subscription.name.toUpperCase()].label}{' '}
                          abonnement.
                        </strong>
                      </p>
                      <p>
                        Du har fortsat adgang til alle funktioner til og med{' '}
                        {new Date(
                          subscription?.nextBillingDate?.toDate()
                        ).toLocaleDateString('da-DK')}
                        . Efter denne dato vil du blive nedgraderet til
                        Freemium.
                      </p>
                    </>
                  )
                )}
                <div className="subscription-plan-actions">
                  <Button onClick={() => setIsPlansModalOpen(true)}>
                    Vælg abonnement
                  </Button>
                </div>
              </div>
            </div>
          </Panel>
          <Panel
            highlighted={
              subscriptionActive && employeesWithMobileAccess.length > 0
                ? 'success'
                : 'default'
            }
          >
            <div
              className={`subscription-plan subscription-plan--${
                subscriptionActive &&
                employeesWithMobileAccess &&
                employeesWithMobileAccess.length > 0
                  ? 'success'
                  : 'default'
              }`}
            >
              <div className="subscription-plan__icon">
                {subscriptionActive &&
                employeesWithMobileAccess &&
                employeesWithMobileAccess.length > 0 ? (
                  <Icon name="check" />
                ) : (
                  <Icon name="close" />
                )}
              </div>
              <div className="subscription-plan__inner">
                <h4>Tidsregistrering</h4>
                <p>
                  Tidsregistrering er et tilkøb på 10 kr. om måneden pr.
                  medarbejder.
                </p>
                {subscriptionActive && employeesWithMobileAccess.length > 0 && (
                  <div className="subscription-plan-employees">
                    <p>
                      <strong>Medarbejdere med adgang: </strong>{' '}
                      {employeesWithMobileAccess.length}{' '}
                      {employeesWithMobileAccess.length === 1
                        ? 'person'
                        : 'personer'}
                      .
                      <br />
                      <strong>Pris:</strong>{' '}
                      {`${employeesWithMobileAccess.length * 10}`} kr. pr.
                      måned.
                    </p>
                    <p>
                      Du kan til enhver tid tilføje eller fjerne adgang til
                      tidsregistrering for enkelte medarbejdere.
                    </p>
                  </div>
                )}
                <div className="subscription-plan-actions">
                  <Button
                    buttonStyle="text-link"
                    className="small"
                    onClick={() => setIsTimeRegistrationAccessModalOpen(true)}
                  >
                    {subscriptionActive && employeesWithMobileAccess.length > 0
                      ? 'Ændre adgang'
                      : 'Aktivér tidsregistrering'}
                  </Button>
                </div>
              </div>
            </div>
          </Panel>
          <Panel
            highlighted={
              subscriptionActive && employeesWithMobileAccess.length > 0
                ? 'success'
                : 'default'
            }
          >
            <div
              className={`subscription-plan subscription-plan--${
                subscriptionActive &&
                employeesWithMobileAccess &&
                employeesWithMobileAccess.length > 0
                  ? 'success'
                  : 'default'
              }`}
            >
              <div className="subscription-plan__icon">
                {subscriptionActive &&
                employeesWithMobileAccess &&
                employeesWithMobileAccess.length > 0 ? (
                  <Icon name="check" />
                ) : (
                  <Icon name="close" />
                )}
              </div>
              <div className="subscription-plan__inner">
                <h4>Medarbejdere med admin adgang</h4>
                <p>
                  Medarbejdere med admin adgang er et tilkøb på 25 kr. om
                  måneden pr. admin.
                </p>
                {subscriptionActive &&
                  employeesWithAdminOrCompanyOwner.length > 0 && (
                    <div className="subscription-plan-employees">
                      <p>
                        <strong>Medarbejdere med adgang: </strong>{' '}
                        {employeesWithAdminOrCompanyOwner.length}{' '}
                        {employeesWithAdminOrCompanyOwner.length === 1
                          ? 'person'
                          : 'personer'}
                        .
                        <br />
                        <strong>Inkluderet i dit abonnement: </strong>{' '}
                        {PLANS[subscription?.name?.toUpperCase()]?.freeAdmins +
                          1}{' '}
                        {PLANS[subscription?.name?.toUpperCase()]?.freeAdmins +
                          1 ===
                        1
                          ? 'person'
                          : 'personer'}
                        .
                        <br />
                        <strong>Pris:</strong>{' '}
                        {`${Math.max(
                          (employeesWithAdminOrCompanyOwner.length -
                            PLANS[subscription?.name?.toUpperCase()]
                              ?.freeAdmins -
                            1) *
                            25,
                          0
                        )}`}{' '}
                        kr. pr. måned.
                      </p>
                      <p>
                        Du kan til enhver tid tilføje eller fjerne admin
                        adgange.
                      </p>
                    </div>
                  )}
                <div className="subscription-plan-actions">
                  <Button
                    buttonStyle="text-link"
                    className="small"
                    onClick={() => setIsAdminAccessModalOpen(true)}
                  >
                    {subscriptionActive &&
                    employeesWithAdminOrCompanyOwner.length > 0
                      ? 'Ændre adgang'
                      : 'Aktivér flere admins'}
                  </Button>
                </div>
              </div>
            </div>
          </Panel>
          <Panel>
            <div className="subscription-plan-next-billing-card">
              <div>
                <h5>Næste faktura</h5>
                {subscription?.nextBillingDate ? (
                  ((subscriptionActive && subscription.name !== 'freemium') ||
                    employeesWithMobileAccess.length) > 0 ? (
                    <p>
                      <strong>Betalingsdato:</strong>{' '}
                      {new Date(
                        subscription?.nextBillingDate?.toDate()
                      ).toLocaleDateString('da-DK')}
                      <br />
                      <strong>Total beløb:</strong>{' '}
                      {Math.max(
                        (employeesWithAdminOrCompanyOwner.length -
                          PLANS[subscription?.name?.toUpperCase()]?.freeAdmins -
                          1) *
                          25,
                        0
                      ) +
                        employeesWithMobileAccess.length * 10 +
                        (subscription?.status === 'active' &&
                          subscription?.price / 100)}{' '}
                      kr. ekskl. moms
                    </p>
                  ) : (
                    <p className="small">
                      Du har ingen aktive abonnementer eller medarbejdere med
                      adgang til tidsregistrering.
                    </p>
                  )
                ) : (
                  <p className="small">
                    Du har ingen aktive abonnementer eller medarbejdere med
                    adgang til tidsregistrering.
                  </p>
                )}
              </div>
              {onPaySubscription?.onPayData?.data ? (
                <div className="subscription-plan-card">
                  <h5>Betalingskort</h5>
                  <div className="subscription-plan-card-inner">
                    <div className="subscription-plan-card-details">
                      {onPaySubscription?.onPayData?.data.card_type ===
                        'dankort' && (
                        <img
                          src={Dankort}
                          alt="Dankort"
                          width="41"
                          height="26"
                        />
                      )}
                      {onPaySubscription?.onPayData?.data.card_type ===
                        'visa' && (
                        <img src={Visa} alt="Visa" width="41" height="26" />
                      )}
                      {onPaySubscription?.onPayData?.data.card_type ===
                        'mastercard' && (
                        <img
                          src={Mastercard}
                          alt="Mastercard"
                          width="41"
                          height="26"
                        />
                      )}
                      <span className="subscription-plan-card__number">
                        {onPaySubscription?.onPayData?.data.card_bin}******
                      </span>
                    </div>
                    <span className="subscription-plan-card-expiry">
                      {expiryDate <= currentDate ? 'Udløbet' : 'Udløber'}:{' '}
                      {onPaySubscription?.onPayData?.data.expiry_month} /{' '}
                      {onPaySubscription?.onPayData?.data.expiry_year}
                    </span>
                    {expiryDate <= currentDate ? (
                      <InfoBox size="small" type="danger" link="/subscription">
                        <strong>Dit betalingskort er udløbet.</strong> Opdater
                        venligst dit betalingskort for at undgå afbrydelse af
                        HRfamly.
                      </InfoBox>
                    ) : expiryDate <= thirtyDaysFromNow ? (
                      <InfoBox size="small" type="warning" link="/subscription">
                        <strong>Dit betalingskort udløber snart.</strong>{' '}
                        Opdater venligst dit betalingskort for at undgå
                        afbrydelse af HRfamly.
                      </InfoBox>
                    ) : null}
                    <div className="subscription-plan-card-actions">
                      <Button
                        buttonStyle="text-link"
                        className="small"
                        onClick={handleCreditCardChange}
                      >
                        Skift betalingskort
                      </Button>{' '}
                    </div>
                  </div>
                </div>
              ) : onPaySubscription ? (
                <div>
                  <h5 id="add-payment-method">Tilføj betalingskort</h5>
                  <p className="small">
                    Ønsker du at fortsætte med dit abonnement efter
                    prøveperioden skal du tilføje et betalingskort.
                  </p>
                  <Button onClick={handleCreditCardChange}>Tilføj nu</Button>
                </div>
              ) : (
                <div>
                  <h5 id="add-payment-method">Tilføj betalingskort</h5>
                  <p className="small">
                    Ønsker du at tegne et abonnement eller bruge
                    tidsregistrering, skal du tilføje et betalingskort.
                  </p>
                  <Button onClick={addCard}>Tilføj nu</Button>
                </div>
              )}
            </div>
            {subscription?.lastPaymentFailed && (
              <InfoBox
                type={'danger'}
                style={{
                  marginTop: '24px',
                }}
              >
                <strong>Seneste betaling mislykkedes</strong>
                <br />
                Tjek venligst dit betalingskort er gyldigt. Vi forsøger at
                trække beløbet igen inden for de næste 24 timer.
              </InfoBox>
            )}
          </Panel>
        </Panels>
      </Panel>
      <SubscriptionPlansModal
        key={`plans-modal-${userInfo?.companyId}`}
        isModalOpen={isPlansModalOpen}
        setIsModalOpen={setIsPlansModalOpen}
        companyId={userInfo?.companyId}
        userId={userInfo.id}
      />
      {isTimeRegistrationAccessModalOpen && (
        <TimeRegistrationAccessModal
          key={`time-registration-access-modal-${userInfo?.companyId}`}
          isModalOpen={isTimeRegistrationAccessModalOpen}
          setIsModalOpen={setIsTimeRegistrationAccessModalOpen}
        />
      )}
      {isAdminAccessModalOpen && (
        <AdminAccessModal
          key={`admin-access-modal-${userInfo?.companyId}`}
          isModalOpen={isAdminAccessModalOpen}
          setIsModalOpen={setIsAdminAccessModalOpen}
        />
      )}
    </>
  );
}
