import { useState, useEffect } from 'react';
import { DayPicker } from 'react-day-picker';
import './date-picker.css';
import Icon from '../icon/icon';
import Select from 'react-select';
import Popover from '../popover/popover';
import ScrollToSelectedMenuList from '../input-field/input-field-menu-list';

const timeOptions = [...Array(24)].flatMap((_, hour) =>
  [...Array(4)].map((_, quarter) => {
    const minutes = quarter * 15;
    const timeValue = `${hour.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
    return { value: timeValue, label: timeValue };
  })
);

export default function DatePicker({
  label,
  name,
  type = 'date',
  value,
  onChange,
  placeholder = 'DDMMÅÅ',
  error,
  forwardRef,
  datePickerPosition,
  datePickerFixed,
  className,
  inline,
  ...props
}) {
  const [inputValue, setInputValue] = useState(
    value ? new Date(value).toLocaleDateString('da-DK') : ''
  );

  const [selectedDate, setSelectedDate] = useState(
    value ? new Date(value) : null
  );

  const [selectedTime, setSelectedTime] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    if (!isDatePickerOpen) {
      const [day, month, year] = inputValue.split('.');
      const parsedDate = new Date(`${year}-${month}-${day}`);

      if (!isNaN(parsedDate)) {
        setSelectedDate(parsedDate);
        if (type === 'date') {
          onChange({
            target: { name, value: parsedDate },
          });
        }
      }
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setInputValue(date.toLocaleDateString('da-DK'));
    if (type === 'date') {
      onChange({ target: { name, value: date } });
    } else if (type === 'datetime' && selectedTime) {
      const combinedDate = mergeDateAndTime(date, selectedTime);
      onChange({
        target: { name, value: combinedDate, type: 'datetime' },
      });
    }

    // Close the date picker
    setIsDatePickerOpen(false);
  };

  // Handle time selection from dropdown for both datetime and time types
  const handleTimeChange = (selectedOption) => {
    setSelectedTime(selectedOption);

    if (type === 'datetime' && selectedDate) {
      // If datetime, merge date and time
      const combinedDateTime = mergeDateAndTime(selectedDate, selectedOption);
      onChange({
        target: {
          name,
          value: combinedDateTime,
          type: 'datetime',
        },
      });
    } else if (type === 'time') {
      // If time only, create a Date object with the current date
      const currentTime = new Date();
      const [hours, minutes] = selectedOption.value.split(':');
      currentTime.setHours(hours);
      currentTime.setMinutes(minutes);
      currentTime.setSeconds(0);
      currentTime.setMilliseconds(0);

      // Call onChange with a Date object
      onChange({
        target: {
          name: props.name,
          value: currentTime,
          type: 'time',
        },
      });
    }
  };

  const mergeDateAndTime = (date, time) => {
    const [hours, minutes] = time.value.split(':');
    const mergedDate = new Date(date);
    mergedDate.setHours(hours, minutes, 0, 0);
    return mergedDate;
  };

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setInputValue(date.toLocaleDateString('da-DK'));
      setSelectedDate(date);

      if (type === 'datetime') {
        const time = `${date.getHours().toString().padStart(2, '0')}:${date
          .getMinutes()
          .toString()
          .padStart(2, '0')}`;
        setSelectedTime({ value: time, label: time });
      }
    }
  }, [type, value]);

  return (
    <div className={`input-field ${inline ? 'input-field--inline' : ''}`}>
      <div className="input-field-group">
        <div
          className={`input-field input-field--date ${
            error && 'input-field--error'
          } ${className ?? ''}
            ${inline ? 'input-field--inline' : ''}
            `}
        >
          <input
            id={label}
            required={true}
            type="text"
            value={inputValue}
            placeholder={placeholder}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // Set the selected date
                const [day, month, year] = inputValue.split('.');
                const parsedDate = new Date(`${year}-${month}-${day}`);
                setSelectedDate(parsedDate);

                // Call onChange with the selected date
                onChange({
                  target: { name, value: parsedDate },
                });

                e.target.blur();

                // Close the date picker
                setIsDatePickerOpen(false);
              }
            }}
            onFocus={() => setIsDatePickerOpen(true)}
            ref={forwardRef}
          />
          <label dangerouslySetInnerHTML={{ __html: label }} />
          <Icon name="calendar today" />
        </div>

        {type === 'datetime' && (
          <div
            className={`input-field input-field--select2 ${
              error && 'input-field--error'
            } ${className ?? ''}
            ${inline ? 'input-field--inline' : ''}
            `}
          >
            <Select
              className="react-select-container"
              classNamePrefix={'react-select'}
              placeholder=""
              value={selectedTime}
              onChange={handleTimeChange}
              options={timeOptions}
              noOptionsMessage={() => 'Ugyldig'}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              isSearchable={false}
              components={{
                MenuList: ScrollToSelectedMenuList,
                DropdownIndicator: () => (
                  <svg
                    className="react-select__dropdown-indicator"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z"
                      fill="rgb(61, 133, 69)"
                    />
                  </svg>
                ),
              }}
              {...props}
            />
            <label dangerouslySetInnerHTML={{ __html: 'Kl.' }} />
          </div>
        )}
      </div>
      {!inline && (
        <span className="help">
          Datoen skal være i formatet være DD.MM.YYYY
        </span>
      )}
      {error && <span className="error">{error}</span>}
      {isDatePickerOpen && (
        <Popover
          onClose={() => setIsDatePickerOpen(false)}
          position={datePickerPosition}
          fixed={datePickerFixed}
        >
          <DayPicker
            captionLayout="dropdown"
            mode="single"
            selected={selectedDate}
            defaultMonth={selectedDate}
            onSelect={handleDateSelect}
            endMonth={new Date('2050-12-31T23:59')}
          />
        </Popover>
      )}
    </div>
  );
}
