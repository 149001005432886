import { currentTimeLogSetting } from '../../utils/currentTimeLogSetting';
import DANISH_HOLIDAYS from '../../constants/time/danishHolidays';
import { convertToUTC } from '../../utils/formatDates';
import { Timestamp } from 'firebase/firestore';

export const calculateUserMetrics = (
  logs,
  userInfoData,
  companyData,
  dateRange
) => {
  const metrics = {
    normHours: 0,
    actualWorkHours: 0,
    overtime: {
      hours: 0,
      hasNote: false,
    },
    sickLeave: {
      hours: 0,
      hasNote: false,
    },
    timeOff: {
      hours: 0,
      hasNote: false,
      timeOffType: '',
    },
    holidays: {
      hours: 0,
      hasNote: false,
    },
    avgHoursPerWeek: 0,
  };

  const datesInRange = [];
  let currentDate = convertToUTC(new Date(dateRange.startDate));

  while (currentDate <= convertToUTC(new Date(dateRange.endDate))) {
    datesInRange.push(currentDate.toISOString().split('T')[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const logsByDate = logs.reduce((acc, log) => {
    acc[convertToUTC(new Date(log.date)).toISOString().split('T')[0]] = log;
    return acc;
  }, {});

  let totalWorkingDays = 0;

  datesInRange.forEach((dateString) => {
    const date = new Date(dateString);
    const log = logsByDate[dateString];
    const timeLogSetting = currentTimeLogSetting(
      companyData,
      userInfoData,
      date
    );

    if (!timeLogSetting) {
      return;
    }

    // Check if the day is a Danish holiday
    const isHoliday = DANISH_HOLIDAYS.some(
      (holiday) => new Date(holiday.value).getTime() === date.getTime()
    );

    // Date is in the future
    const isFutureDate = date > new Date();

    let normHours = 0;
    if (
      !isFutureDate &&
      timeLogSetting &&
      !timeLogSetting.notWorking &&
      !isHoliday
    ) {
      totalWorkingDays++;

      // Calculate norm hours
      normHours =
        (timeLogSetting.end.toMillis() - timeLogSetting.from.toMillis()) /
        (1000 * 60 * 60);

      // Handle break duration
      if (timeLogSetting.breakDuration > 0) {
        let breakDuration = timeLogSetting.breakDuration / 60;
        if (timeLogSetting.paidBreak) {
          breakDuration = 0;
        }
        normHours -= breakDuration;
      }

      // Add norm hours to total norm hours
      metrics.normHours += normHours;
    }

    // Initialize daily totals
    let dailyWorkHours = normHours;
    let dailyOvertime = { hours: 0, hasNote: false };
    let dailySickLeave = { hours: 0, hasNote: false };
    let dailyTimeOff = { hours: 0, hasNote: false, timeOffType: '' };
    let dailyHoliday = { hours: 0, hasNote: false };

    // Adjust for log entries if they exist
    if (log) {
      log.entries.forEach((entry) => {
        const hasNote = entry.note && entry.note.trim() !== '';
        switch (entry.type) {
          case 'work':
            dailyWorkHours += entry.hours;
            break;
          case 'overtime':
            dailyOvertime.hours += entry.hours;
            dailyOvertime.hasNote = dailyOvertime.hasNote || hasNote;
            dailyWorkHours += entry.hours; // Ensure overtime is added to actual work hours
            break;
          case 'sickLeave':
            dailySickLeave.hours += entry.hours;
            dailySickLeave.hasNote = dailySickLeave.hasNote || hasNote;
            break;
          case 'timeOff':
            dailyTimeOff.hours += entry.hours;
            dailyTimeOff.hasNote = dailyTimeOff.hasNote || hasNote;
            dailyTimeOff.timeOffType = entry.timeOffType;
            break;
          case 'holiday':
            dailyHoliday.hours += entry.hours;
            dailyHoliday.hasNote = dailyHoliday.hasNote || hasNote;
            break;
          default:
            break;
        }
      });
      // Adjust work hours based on deviations
      dailyWorkHours -=
        dailySickLeave.hours + dailyTimeOff.hours + dailyHoliday.hours;
    }

    // Update metrics
    metrics.actualWorkHours += dailyWorkHours;
    metrics.overtime.hours += dailyOvertime.hours;
    metrics.overtime.hasNote =
      metrics.overtime.hasNote || dailyOvertime.hasNote;
    metrics.sickLeave.hours += dailySickLeave.hours;
    metrics.sickLeave.hasNote =
      metrics.sickLeave.hasNote || dailySickLeave.hasNote;
    metrics.timeOff.hours += dailyTimeOff.hours;
    metrics.timeOff.hasNote = metrics.timeOff.hasNote || dailyTimeOff.hasNote;
    metrics.timeOff.timeOffType =
      dailyTimeOff.timeOffType || metrics.timeOff.timeOffType;
    metrics.holidays.hours += dailyHoliday.hours;
    metrics.holidays.hasNote = metrics.holidays.hasNote || dailyHoliday.hasNote;
  });

  const weeks = totalWorkingDays / 5; // assuming 5 working days per week
  metrics.avgHoursPerWeek = weeks > 0 ? metrics.actualWorkHours / weeks : 0;

  return metrics;
};

export const calculateAllUsersMetrics = (
  logsByUser,
  employees,
  companyData,
  dateRange,
  orderByDate = false
) => {
  if (orderByDate) {
    const metricsByDate = {};
    const datesInRange = [];
    let currentDate = convertToUTC(new Date(dateRange.startDate));

    while (currentDate <= convertToUTC(new Date(dateRange.endDate))) {
      datesInRange.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    datesInRange.forEach((dateString) => {
      metricsByDate[dateString] = {};
      employees.forEach((employee) => {
        const userId = employee.id;
        const userLogs = logsByUser[userId] || [];
        const userLogsForDate = userLogs.filter(
          (log) =>
            convertToUTC(new Date(log.date)).toISOString().split('T')[0] ===
            dateString
        );
        const metrics = calculateUserMetrics(
          userLogsForDate,
          employee.data,
          companyData,
          { startDate: dateString, endDate: dateString }
        );
        metricsByDate[dateString][userId] = metrics;
      });
    });

    return metricsByDate;
  } else {
    const allUsersMetrics = {};

    employees.forEach((employee) => {
      const userId = employee.id;
      const userLogs = logsByUser[userId] || [];
      allUsersMetrics[userId] = calculateUserMetrics(
        userLogs,
        employee.data,
        companyData,
        dateRange
      );
    });

    return allUsersMetrics;
  }
};

export const convertTimeOffTypeUUIDToName = (timeOffTypeUUID, timeOffTypes) => {
  const timeOffType = timeOffTypes.find(
    (type) => type.value === timeOffTypeUUID
  );
  return timeOffType ? timeOffType.label : '';
};

export const getDefaultWeeklySchedule = () => {
  const setWorkHours = (hours, minutes = 0) => {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return Timestamp.fromDate(date);
  };

  return [
    {
      day: 'Monday',
      end: setWorkHours(16, 0),
      from: setWorkHours(8, 0),
      notWorking: false,
    },
    {
      day: 'Tuesday',
      end: setWorkHours(16, 0),
      from: setWorkHours(8, 0),
      notWorking: false,
    },
    {
      day: 'Wednesday',
      end: setWorkHours(16, 0),
      from: setWorkHours(8, 0),
      notWorking: false,
    },
    {
      day: 'Thursday',
      end: setWorkHours(16, 0),
      from: setWorkHours(8, 0),
      notWorking: false,
    },
    {
      day: 'Friday',
      end: setWorkHours(15, 30),
      from: setWorkHours(8, 0),
      notWorking: false,
    },
    {
      day: 'Saturday',
      end: setWorkHours(16, 0),
      from: setWorkHours(8, 0),
      notWorking: true,
    },
    {
      day: 'Sunday',
      end: setWorkHours(16, 0),
      from: setWorkHours(8, 0),
      notWorking: true,
    },
  ];
};
