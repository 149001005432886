import { useRef, useContext, useCallback, useState, useEffect } from 'react';
import { UserInfoContext, EmployeesContext } from '../../context';
import {
  doc,
  collection,
  addDoc,
  Timestamp,
  arrayUnion,
  updateDoc,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../firebase-config';
import Button from '../../components/button/button';
import Modal from '../../components/modal/modal';
import InputField from '../../components/input-field/input-field';
import { useForm } from '../../hooks/useForm';
import AGREEMENT_TYPE from '../../constants/fields/agreementType';
import FileUpload from '../../components/file-upload/file-upload';
import './agreements-create-modal.css';
// import EmployeeSelect from '../../components/employee-select/employee-select';

const uploadFiles = async (files, companyId, agreementId) => {
  // Check for early return conditions
  if (!files || !companyId || !agreementId) return false;

  // Normalize files to an array (handles both single file and FileList objects)
  const filesArray = files instanceof File ? [files] : Array.from(files);

  if (filesArray.length === 0) return false;

  await Promise.all(
    filesArray.map(async (file) => {
      const filePath = `${companyId}/agreements/${agreementId}/${file.name}`;
      const fileRef = ref(storage, filePath);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);

      const fileData = {
        date: Timestamp.fromDate(new Date()),
        extension: file.name.split('.').pop(),
        filePath: fileUrl,
        name: file.name,
      };

      const agreementDoc = doc(db, 'agreements', agreementId);
      await updateDoc(agreementDoc, {
        files: arrayUnion(fileData),
      });

      return fileData;
    })
  );
  return true;
};

export default function AgreementsCreateModal({
  isModalOpen,
  setIsModalOpen,
  selectedAgreementType,
  selectedRegardingId,
}) {
  const fileUploadRef = useRef();
  const employees = useContext(EmployeesContext);
  const userInfo = useContext(UserInfoContext);
  const [formFeedback, setFormFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Form validation
  const validateAgreementForm = (values) => {
    let errors = {};

    if (!values.title.trim()) errors.title = 'Titel mangler';
    if (!values.date) errors.date = 'Dato mangler';
    if (!values.regarding) errors.regarding = 'Vælg en medarbejder';
    if (!values.agreementType) errors.agreementType = 'Vælg en aftaletype';

    return errors;
  };

  // Handle agreement type change
  const handleAgreementTypeChange = (event) => {
    setValues({ ...values, agreementType: event.target.value });

    // Set the title based on the selected agreement type
    const agreementType = AGREEMENT_TYPE.find(
      (type) => type.value === event.target.value
    );

    if (agreementType) {
      setValues({
        ...values,
        agreementType: event.target.value,
        title: agreementType.label,
      });
    }
  };

  // Handle regarding change
  const handleRegardingChange = (event) => {
    // Also add the regarding user to the attendees list
    if (!values.attendees.includes(event.target.value)) {
      setValues({
        ...values,
        regarding: event.target.value,
        attendees: [...values.attendees, event.target.value],
      });
    } else {
      setValues({
        ...values,
        regarding: event.target.value,
      });
    }
  };

  // Handle form submission
  const onSubmit = useCallback(
    async (values) => {
      // Convert dates to Firestore Timestamps
      const valuesToSave = {
        ...values,
        date: Timestamp.fromDate(new Date(values.date)),
        regarding: doc(db, 'users', values.regarding),
        attendees: values.attendees.map((attendeeId) =>
          doc(db, 'users', attendeeId)
        ),
        companyId: userInfo.companyId,
        createdBy: doc(db, 'users', userInfo.id),
        isClosed: false,
      };

      const newAgreementRef = collection(db, 'agreements');

      setIsSubmitting(true);

      try {
        // Add a new document with a generated id.
        const docRef = await addDoc(newAgreementRef, valuesToSave);

        // Check if there are selected files to upload
        const files = fileUploadRef.current.getSelectedFiles();
        if (files && files.length > 0) {
          // Upload files using the agreementId
          await uploadFiles(files, userInfo.companyId, docRef.id);

          // Optionally, clear the selected files from the FileUpload component
          fileUploadRef.current.clearSelectedFiles();
        }

        // Close the modal
        setIsModalOpen(false);

        // Use window.location to force a full page reload
        window.location.reload();
      } catch (error) {
        // Handle any errors
        setFormFeedback('Der skete en fejl. Prøv igen senere.');
        console.error('Error adding document: ', error);

        setIsSubmitting(false);
      }
    },
    [userInfo, fileUploadRef, setIsModalOpen]
  );

  // Custom hook for handling form state
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    {
      title: selectedAgreementType ? selectedAgreementType : '',
      location: '',
      date: new Date(Math.ceil(Date.now() / 1800000) * 1800000).toISOString(), // next closest half hour
      agreementType: selectedAgreementType || undefined,
      regarding: selectedRegardingId || '',
      attendees: selectedRegardingId
        ? [
            selectedRegardingId !== userInfo.id
              ? selectedRegardingId
              : undefined,
            userInfo.id,
          ].filter(Boolean)
        : [userInfo.id],
      description: '',
      note: '',
    },
    validateAgreementForm,
    onSubmit
  );

  // UseEffect to update selectedAgreementType and selectedRegardingId when the modal is opened
  useEffect(() => {
    if (isModalOpen) {
      setValues({
        ...values,
        title: selectedAgreementType ? selectedAgreementType : '',
        agreementType: selectedAgreementType || undefined,
        regarding: selectedRegardingId || '',
        attendees: selectedRegardingId
          ? [
              selectedRegardingId !== userInfo.id
                ? selectedRegardingId
                : undefined,
              userInfo.id,
            ].filter(Boolean)
          : [userInfo.id],
      });
    }
  }, [isModalOpen]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      customWidth={800}
      header={`Ny aftale`}
      bottom={
        <>
          {formFeedback && (
            <span className="agreement-create-modal-feedback">
              {formFeedback}
            </span>
          )}
          <Button disabled={isSubmitting} onClick={handleSubmit}>
            {isSubmitting ? 'Opretter...' : 'Opret'}
          </Button>
        </>
      }
      content={
        <div className="agreement-create-modal-content">
          <div className="agreement-create-modal-content__left">
            <InputField
              required
              label="Aftaletype"
              type="select"
              name="agreementType"
              value={values.agreementType}
              options={AGREEMENT_TYPE}
              onChange={handleAgreementTypeChange}
              placeholder="- Vælg -"
              error={errors.agreementType}
            />
            <InputField
              required
              label="Titel"
              name="title"
              value={values.title}
              onChange={handleChange}
              placeholder="Titel"
              error={errors.title}
              help={`Titlen kommer med hvis aftalen tilføjes til kalenderen.`}
            />
            <InputField
              label="Lokation"
              name="location"
              value={values.location}
              onChange={handleChange}
              placeholder="Lokation"
            />
            <InputField
              required
              label="Dato"
              name="date"
              type="datetime"
              datePickerPosition="bottom"
              datePickerFixed={true}
              value={values.date}
              onChange={handleChange}
              placeholder="Dato"
              error={errors.date}
            />
            {/* <EmployeeSelect
              required
              label="Vedrørende"
              name="regarding"
              onChange={handleRegardingChange}
              value={values.regarding}
              error={errors.regarding}
            /> */}

            <InputField
              required
              label="Vedrørende"
              name="regarding"
              type="select2"
              onChange={handleRegardingChange}
              value={
                values.regarding
                  ? {
                      value: values.regarding,
                      label: `${
                        employees?.find((e) => e.id === values.regarding)?.data
                          .firstName
                      } ${
                        employees?.find((e) => e.id === values.regarding)?.data
                          .lastName
                      }`,
                      target: {
                        value: values.regarding,
                        name: 'regarding',
                      },
                    }
                  : undefined
              }
              options={
                employees?.map((employee) => ({
                  value: employee.id,
                  label: `${employee.data.firstName} ${employee.data.lastName}`,
                  target: {
                    value: employee.id,
                    name: 'regarding',
                  },
                })) || []
              }
              error={errors.regarding}
            />
            <InputField
              label="Deltagere"
              name="attendees"
              type="select2"
              value={values.attendees.map((attendeeId) => {
                const employee = employees.find((e) => e.id === attendeeId);
                return {
                  value: attendeeId,
                  label: employee
                    ? `${employee.data.firstName} ${employee.data.lastName}`
                    : '',
                  target: {
                    value: attendeeId,
                    name: 'attendees',
                  },
                };
              })}
              onChange={(selectedOptions) => {
                // Create an event array or single event object based on selection
                const event = selectedOptions.map((option) => ({
                  target: {
                    name: 'attendees',
                    value: option.value,
                  },
                }));
                // Call handleChange with the constructed event and explicit field name
                handleChange(event.length > 0 ? event : [], 'attendees');
              }}
              isMulti
              options={employees.map((employee) => ({
                value: employee.id,
                label: `${employee.data.firstName} ${employee.data.lastName}`,
                target: {
                  value: employee.id,
                  name: 'attendees',
                },
              }))}
            />
          </div>
          <div className="agreement-create-modal-content__right">
            <FileUpload uploadImmediately={false} ref={fileUploadRef} />
            <div className="agreement-create-modal-content__right__fields">
              <InputField
                label="Beskrivelse"
                type="textarea"
                name="summary"
                value={values.summary}
                onChange={handleChange}
                placeholder="Beskrivelse"
                help={`Beskrivelsen kommer med hvis aftalen tilføjes til kalenderen.`}
              />
              <InputField
                label="Noter"
                name="notes"
                type="textarea"
                value={values.notes}
                onChange={handleChange}
                placeholder="Noter"
              />
            </div>
          </div>
        </div>
      }
    />
  );
}
